import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IndivItem from "../components/shop/indivitem"

import { InstructionsProvider } from "../components/shop/context/instructionsContext"

export const query = graphql`
  query($slug: String!) {
    allDatoCmsPremadeBox(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          name
          price
          description
          slug
          photos {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          supplementsInTheBox {
          name
          instructions
          ingredientAndDosage
        }
        }
      }
    }
  }
`

const PremadeBox = ({ data }) => {
  const product = data.allDatoCmsPremadeBox.edges[0].node

  return (
    <Layout>
      <SEO title={product.name} />
      <InstructionsProvider
        value={{
          productType: 'premadeBox',
          supplementsInTheBox: product.supplementsInTheBox
        }}
      >
        <IndivItem product={product} />
      </InstructionsProvider>
    </Layout>
  )
}

export default PremadeBox
